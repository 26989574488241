<template>
  <!-- 打开时页面提示 -->
  <div class="cover-box">
    <i class="waiting-icon"></i>
    <span class="waiting-text">......</span>
  </div>
  <!-- 打开时页面提示 -->
</template>

<script>
/* eslint-disable */
import Loading from "@/components/Loading";
import MobileHeader from "@/components/MobileHeader";
import { mapState } from "vuex";
import moment from "moment";
import wx from "weixin-js-sdk";
import Weutils from "@/lib/weconfig";
import {
  REQUEST_JSAPI_TICKET,
  REQUEST_PROFILE,
  REQUEST_DEVICE_ITEMS,
  REQUEST_COUPON_INFO,
  REQUEST_PAYMENT,
  CANCEL_PAYMENT
} from "@/store/mutation-types";
export default {
  components: {
    MobileHeader,
    Loading
  },
  data() {
    return {
      isClicked: false,
      quotationCode: localStorage.getItem("quotation_code"),
      client: localStorage.getItem("client"),
      payType: localStorage.getItem("client").toUpperCase(),
      couponCode: "",
      equipmentId: localStorage.getItem("equipment_id"),
      deviceId: localStorage.getItem("device_id"),
      itemId: localStorage.getItem("item_id"),
      payMethod:
        localStorage.getItem("client") === "wechat"
          ? "微信"
          : localStorage.getItem("client") === "alipay"
          ? "支付宝"
          : ""
    };
  },
  created: async function() {
    // 获取手机贴膜信息
    this.requestDeviceItems({
      equipmentId: this.equipmentId,
      deviceId: this.deviceId
    });
    if (this.client === "wechat") {
      // 获取当前页面url
      const url = location.href.split("#")[0];
      // console.log('url: ' + url);
      // 获取jsapi_ticket
      await this.$store.dispatch(REQUEST_JSAPI_TICKET);
      // console.log('JSAPI_TICKET: ' + this.User.ticket);
      // 微信授权
      let weutils = new Weutils(this.User.ticket, url);
      await weutils.config(await this.$getProfileValue("WECHAT_APP_ID"));
    }
    // 获取用户信息
    const code =
      this.client === "alipay"
        ? this.$getQueryString("auth_code")
        : this.$getQueryString("code");
    if (!code) {
      this.$router.push(`/index?quotationCode=${quotationCode}`);
    }
    await this.$store.dispatch(REQUEST_PROFILE, {
      mode: "index",
      client: this.client,
      login_code: code,
      equipmentId: this.equipmentId,
      userToken: localStorage.getItem("__user_token__") || ""
    });
    localStorage.setItem("__user_token__", this.User.token);
  },
  computed: {
    ...mapState({
      deviceItems: state => state.DeviceItems.data,
      User: state => state.User,
      payment: state => state.Payment,
      coupon: state => state.Coupon,
      errmsg: state => state.User.err,
      calculateSalePrice() {
        let sale_price = this.deviceItems.device_items[0].items[0].item_price;
        if (this.payType == "COUPON") {
          sale_price = 0;
        }
        return sale_price;
      }
    })
  },
  mounted() {
    setTimeout(async () => {
      await this.payNow(
        this.quotationCode,
        this.client,
        this.payType.toUpperCase(),
        this.couponCode,
        this.equipmentId,
        this.deviceId,
        this.itemId
      );
    }, 1000);
  },
  methods: {
    requestJsApiTiket() {
      this.$store.dispatch(REQUEST_JSAPI_TICKET);
    },
    requestProfile(params) {
      this.$store.dispatch(REQUEST_PROFILE, params);
    },
    requestDeviceItems(params) {
      this.$store.dispatch(REQUEST_DEVICE_ITEMS, params);
    },
    async checkCoupon() {
      if (this.couponCode != null || this.couponCode != "") {
        // 调用接口验证兑换码，同时，若兑换码可用，【支付方式】改为“兑换码”
        await this.$store.dispatch(REQUEST_COUPON_INFO, {
          couponCode: this.couponCode,
          checkDate: moment().format("YYYY-MM-DD HH:mm:ss")
        });
        if (JSON.stringify(this.coupon.data) == "{}") {
          // alert(JSON.stringify(this.coupon.err));
          alert(this.coupon.err);
          this.couponCode = "";
          return;
        } else {
          this.payMethod = "兑换码";
          this.payType = "COUPON";
          alert(
            "此兑换码剩余可使用次数：" +
              JSON.stringify(this.coupon.data.coupon_remained_count)
          );
        }
      }
    },
    async payNow(
      quotationCode,
      client,
      payType,
      couponCode,
      equipmentId,
      deviceId,
      itemId
    ) {
      if (!this.isClicked) {
        this.isClicked = true;
        let quotationInfo = await this.$getQuotationInfo(quotationCode);
        console.log(quotationInfo);
        if (!quotationInfo.data) {
          alert(quotationInfo.errmsg);
        } else if (!this.User.loading) {
          try {
            if (payType == "COUPON") {
              // console.log('coupon payment');
              await this.$store.dispatch(REQUEST_PAYMENT, {
                client,
                orderType: "STICKING_SERVICE",
                orderDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                equipmentId,
                deviceId,
                itemId,
                payType,
                couponCode,
                quotationCode
              });
              this.$router.push("/waiting");
              // if (JSON.stringify(this.payment.data) !== '{}' && JSON.stringify(this.payment.err) === '{}') {
              //     location.assign(this.payment.data.data);
              // } else if (JSON.stringify(this.payment.data) === '{}' && JSON.stringify(this.payment.err) !== '{}') {
              //     alert('支付失败，请重试！'+this.payment.err.errmsg);
              // } else if (JSON.stringify(this.payment.data) === '{}' && JSON.stringify(this.payment.err) === '{}') {
              //     this.$router.push('/waiting');
              // }
            } else if (client === "wechat" && payType == "WECHAT") {
              // console.log('wechat payment')
              await this.$store.dispatch(REQUEST_PAYMENT, {
                quotationCode,
                client,
                orderType: "STICKING_SERVICE",
                orderDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                equipmentId,
                deviceId,
                itemId,
                payType,
                couponCode
              });

              if (
                JSON.stringify(this.payment.data) !== "{}" &&
                JSON.stringify(this.payment.err) === "{}"
              ) {
                wx.chooseWXPay({
                  timestamp: this.payment.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                  nonceStr: this.payment.data.nonceStr, // 支付签名随机串，不长于 32 位
                  package: this.payment.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                  signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                  paySign: this.payment.data.paySign, // 支付签名
                  success: res => {
                    // 支付成功后的回调函数
                    this.$router.push("/waiting");
                  },
                  fail: async err => {
                    //   console.log(err);
                    await this.$store.dispatch(CANCEL_PAYMENT, {
                      equipmentId,
                      itemId
                    });
                    alert("支付失败，请重试！");
                    this.isClicked = false;
                  },
                  cancel: async res => {
                    //用户点击取消时的回调函数，仅部分有用户取消操作的api才会用到。
                    //   console.log('cancel')
                    await this.$store.dispatch(CANCEL_PAYMENT, {
                      equipmentId,
                      itemId
                    });
                    alert("用户主动取消了支付，请重新支付！");
                    this.isClicked = false;
                  }
                });
              } else if (
                JSON.stringify(this.payment.data) === "{}" &&
                JSON.stringify(this.payment.err) !== "{}"
              ) {
                alert("支付失败，请重试！" + this.payment.err.errmsg);
                this.isClicked = false;
              } else if (
                JSON.stringify(this.payment.data) === "{}" &&
                JSON.stringify(this.payment.err) === "{}"
              ) {
                alert("Waiting......");
              }
            } else if (client === "alipay" && payType == "ALIPAY") {
              // console.log('alipay payment')
              await this.$store.dispatch(REQUEST_PAYMENT, {
                client,
                orderType: "STICKING_SERVICE",
                orderDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                equipmentId,
                deviceId,
                itemId,
                payType,
                couponCode,
                quotationCode
              });
              if (
                JSON.stringify(this.payment.data) !== "{}" &&
                JSON.stringify(this.payment.err) === "{}"
              ) {
                location.assign(this.payment.data.data);
              } else if (
                JSON.stringify(this.payment.data) === "{}" &&
                JSON.stringify(this.payment.err) !== "{}"
              ) {
                // alert("支付失败，请重试！" + this.payment.err.errmsg); //2019
                this.isClicked = false;
              } else if (
                JSON.stringify(this.payment.data) === "{}" &&
                JSON.stringify(this.payment.err) === "{}"
              ) {
                this.$router.push("/waiting");
              }
            }
          } catch (err) {
            //   console.log(err)
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// 打开时页面提示
.cover-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  overflow: hidden;
  text-align: center;
  .waiting-icon {
    display: inline-block;
    margin-top: 50px;
    height: 130px;
    width: 130px;
    background-image: url(~@/assets/mobile/loading.gif);
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .waiting-text {
    display: block;
    line-height: 2;
  }
}
// 打开时页面提示
</style>
