<template>
  <div class="loading">
    <img src="@/assets/mobile/loading.gif" alt="loading">
    <!-- <span>Loading...</span> -->
  </div>
</template>

<script>
  export default {
    name: 'Loading'
  }
</script>

<style lang='scss' scoped>
  .loading{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
    z-index: 999;
    img{
      width: 60px;
      height: 60px;
    }
  }
</style>