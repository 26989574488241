/* eslint-disable */
import sign from './wesign';
import wx from 'weixin-js-sdk';
class WechatUtil {

  constructor (jsapiTicket, url) {
    this.res = sign(jsapiTicket, url)
    this.jsApiList = ['chooseWXPay','getLocation']
  }

  config (appId) {
    return new Promise((resolve, reject) => {
      wx.config({
        debug: false,
        appId: appId,
        timestamp: this.res.timestamp,
        nonceStr: this.res.nonceStr,
        signature: this.res.signature,
        jsApiList: this.jsApiList.slice()
      })
      wx.ready((res) => {
        resolve(true);
        //alert('weconfig Configurated!');
      })
      wx.error(function (err) {
        alert(err)
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      })
    })
  }

  checkJsApi () {
    return new Promise((resolve, reject) => {
      wx.checkJsApi({
        jsApiList: ['chooseWXPay'],
        success: (res) => {
          if (res.checkResult.getLocation === false) {
            reject({errmsg: '您的微信版本太低不支持微信JS接口，请升级到最新的微信版本！'})
          } else {
            resolve(true)
          }
        }
      })
    })
  }
}

export default WechatUtil
